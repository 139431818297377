export default {
  namespaced: true,
  state: {
    filters: {
      intAirport: 0,
      airportConnectivity: 0,
      maxInternationalTrainStation: 0,
      hasConventionBureau: false,
      isSustainableCommitment: false
    },
    validate: {
      intAirport: false,
      airportConnectivity: false,
      maxInternationalTrainStation: false,
      hasConventionBureau: false,
      isSustainableCommitment: false
    },
    appliedCityMatchFilters: [],
    shortListedCity: [],
    matchCity: [],
    filteredMatchCity: [],
    matchCityForm: {}
  },
  mutations: {
    CLEAR_DATA(state) {
      state.shortListedCity = [];
    },
    ADD_CITY(state, payload) {
      let shortListedCity = state.shortListedCity;

      let index = shortListedCity.findIndex(x => x._id === payload._id);
      if (index != -1) {
        shortListedCity.splice(index, 1);
      } else {
        if (state.shortListedCity.length < 9) {
          shortListedCity.push(payload);
        }
      }
      state.shortListedCity = shortListedCity;
    },
    SET_MATCH_CITY(state, payload) {
      state.matchCity = payload;
      this.commit("shortList/APPLIED_CITY_MATCH_FILTER");
    },
    INIT_AIRPORT(state, payload) {
      state.filters.intAirport = payload;
    },
    AIRPORT_CONNECTIVITY(state, payload) {
      state.filters.airportConnectivity = payload;
    },
    MAX_INTERNATIONAL_TRAINSTATION(state, payload) {
      state.filters.maxInternationalTrainStation = payload;
    },
    CONV_BUREAU(state, payload) {
      state.filters.hasConventionBureau = payload;
    },
    SUSTAINABLE_COMMITMENT(state, payload) {
      state.filters.isSustainableCommitment = payload;
    },
    VALIDATE(state, payload) {
      state.validate = {
        ...state.validate,
        ...payload
      };
      this.commit("shortList/APPLIED_CITY_MATCH_FILTER");
    },
    MATCH_CITY_PAYLOAD(state, payload) {
      state.matchCityForm = payload;
    },
    APPLIED_CITY_MATCH_FILTER(state) {
      let validate = state.validate;
      let matchCity = JSON.parse(JSON.stringify(state.matchCity));
      var appliedCityMatchFilters = [...state.appliedCityMatchFilters];

      if (validate.intAirport) {
        let maxInternationalAirportLabels = [10, 20, 30, 40, 50, 100];
        matchCity = matchCity.filter(city => {
          if (
            city.distanceToInterAirport >
            maxInternationalAirportLabels[state.filters.intAirport]
          ) {
            return city;
          }
        });

        let index1 = appliedCityMatchFilters.findIndex(
          x => x.id === "intAirport"
        );

        if (index1 == -1) {
          appliedCityMatchFilters.push({
            id: "intAirport",
            str:
              "Int Airport Dis " +
              maxInternationalAirportLabels[state.filters.intAirport] +
              " Km"
          });
        } else {
          appliedCityMatchFilters[index1] = {
            id: "intAirport",
            str:
              "Int Airport Dis " +
              maxInternationalAirportLabels[state.filters.intAirport] +
              " Km"
          };
        }
      }

      if (validate.airportConnectivity) {
        let airportConnectivityThumbs = [200, 400, 900, 1000];
        matchCity = matchCity.filter(city => {
          if (
            city.airportConnectivity >
            airportConnectivityThumbs[state.filters.airportConnectivity]
          ) {
            return city;
          }
        });

        let index2 = appliedCityMatchFilters.findIndex(
          x => x.id === "airportConnectivity"
        );
        if (index2 == -1) {
          appliedCityMatchFilters.push({
            id: "airportConnectivity",
            str:
              "Airport Connectivity " +
              airportConnectivityThumbs[state.filters.airportConnectivity]
          });
        } else {
          appliedCityMatchFilters[index2] = {
            id: "airportConnectivity",
            str:
              "Airport Connectivity " +
              airportConnectivityThumbs[state.filters.airportConnectivity]
          };
        }

        console.log("airportConnectivity", index2);
      }

      if (validate.maxInternationalTrainStation) {
        let maxInternationalTrainStationLabels = [10, 20, 30, 40, 50, 100];
        matchCity = matchCity.filter(city => {
          if (
            city.distanceToIntRailway >
            maxInternationalTrainStationLabels[
              state.filters.maxInternationalTrainStation
            ]
          ) {
            return city;
          }
        });

        let index3 = appliedCityMatchFilters.findIndex(
          x => x.id === "maxInternationalTrainStation"
        );

        if (index3 == -1) {
          appliedCityMatchFilters.push({
            id: "maxInternationalTrainStation",
            str:
              "Int Railway Dis " +
              maxInternationalTrainStationLabels[
                state.filters.maxInternationalTrainStation
              ]
          });
        } else {
          appliedCityMatchFilters[index3] = {
            id: "maxInternationalTrainStation",
            str:
              "Int Railway Dis " +
              maxInternationalTrainStationLabels[
                state.filters.maxInternationalTrainStation
              ]
          };
        }
      }

      if (validate.hasConventionBureau) {
        matchCity = matchCity.filter(city => {
          if (city.hasConventionBureau == state.filters.hasConventionBureau) {
            return city;
          }
        });

        let index4 = appliedCityMatchFilters.findIndex(
          x => x.id === "hasConventionBureau"
        );

        if (index4 == -1) {
          appliedCityMatchFilters.push({
            id: "hasConventionBureau",
            str: `Convention Bureau  ${
              state.filters.hasConventionBureau ? "Yes" : "No"
            }`
          });
        } else {
          appliedCityMatchFilters[index4] = {
            id: "hasConventionBureau",
            str: `Convention Bureau  ${
              state.filters.hasConventionBureau ? "Yes" : "No"
            }`
          };
        }
      }

      if (validate.isSustainableCommitment) {
        matchCity = matchCity.filter(city => {
          if (
            city.isSubventionOffered == state.filters.isSustainableCommitment
          ) {
            return city;
          }
        });

        let index5 = appliedCityMatchFilters.findIndex(
          x => x.id === "isSubventionOffered"
        );
        if (index5 == -1) {
          appliedCityMatchFilters.push({
            id: "isSubventionOffered",
            str: `Sustainability Commitment 
            ${state.filters.isSustainableCommitment ? "Yes" : "No"}`
          });
        } else {
          appliedCityMatchFilters[index5] = {
            id: "isSubventionOffered",
            str: `Sustainability Commitment 
              ${state.filters.isSustainableCommitment ? "Yes" : "No"}`
          };
        }
      }

      console.log(appliedCityMatchFilters);
      state.appliedCityMatchFilters = [...appliedCityMatchFilters];
      state.filteredMatchCity = matchCity;
    },
    REMOVE_CITY(state, payload) {
      let shortListedCity = state.shortListedCity;
      shortListedCity.splice(payload, 1);
      state.shortListedCity = shortListedCity;
    },
    CLEAR_SHORT_LISTED_CITY(state) {
      let shortListedCity = [];
      state.shortListedCity = shortListedCity;
    },
    SET_CLEAR(state, payload) {
      let appliedCityMatchFilters = [...state.appliedCityMatchFilters];

      let index = appliedCityMatchFilters.findIndex(x => x.id === payload);
      console.log(index);

      appliedCityMatchFilters.splice(index, 1);
      console.log(payload);

      let validate = state.validate;
      validate[payload] = false;
      state.validate = { ...validate };
      this.commit("shortList/APPLIED_CITY_MATCH_FILTER");
      state.appliedCityMatchFilters = appliedCityMatchFilters;
    }
  },
  actions: {
    clearState({ commit }) {
      commit("CLEAR_DATA");
    },
    addCity({ commit }, payload) {
      commit("ADD_CITY", payload);
    },
    removeCity({ commit }, payload) {
      commit("REMOVE_CITY", payload);
    },
    clearShortListedCity({ commit }) {
      commit("CLEAR_SHORT_LISTED_CITY");
    },
    intAirport({ commit }, payload) {
      commit("INIT_AIRPORT", payload);
    },
    airportConnectivity({ commit }, payload) {
      commit("AIRPORT_CONNECTIVITY", payload);
    },
    setMatchCity({ commit }, payload) {
      commit("SET_MATCH_CITY", payload);
    },
    IntTrain({ commit }, payload) {
      commit("MAX_INTERNATIONAL_TRAINSTATION", payload);
    },
    ConvBureau({ commit }, payload) {
      commit("CONV_BUREAU", payload);
    },
    SustainableCommitment({ commit }, payload) {
      commit("SUSTAINABLE_COMMITMENT", payload);
    },
    validate({ commit }, payload) {
      commit("VALIDATE", payload);
    },
    matchCityPayload({ commit }, payload) {
      commit("MATCH_CITY_PAYLOAD", payload);
    },
    setClear({ commit }, payload) {
      commit("SET_CLEAR", payload);
    }
  },
  getters: {
    getShortListedCity: state => state.shortListedCity,
    //Filter
    getAppliedCityMatchFilters: state => state.appliedCityMatchFilters,
    getIntAirport: state => state.filters.intAirport,
    getAirportConnectivity: state => state.filters.airportConnectivity,
    getMatchCity: state => state.filteredMatchCity,
    getHasConventionBureau: state => state.filters.hasConventionBureau,
    getIsSustainableCommitment: state => state.filters.isSustainableCommitment,
    getMatchCityForm: state => state.matchCityForm,
    getMaxInternationalTrainStation: state =>
      state.filters.maxInternationalTrainStation
  }
};
