import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    drawer: null,
    user: null,
    userEmail: null
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_USER_EMAIL(state, payload) {
      state.userEmail = payload;
    }
  },
  actions: {
    setUser({ commit }, payload) {
      commit("SET_USER", payload.user);
    },
    setUserEmail({ commit }, payload) {
      commit("SET_USER_EMAIL", payload);
    }
  },
  getters: {
    getUserEmail: state => state.userEmail
  }
});
