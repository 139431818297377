export default {
  namespaced: true,
  state: {
    organisationProfileEl: 1,
    user: {
      userType: null,
      _id: null,
      name: null,
      email: null,
      organisation: null,
      created_at: null
    },
    isProfile: false,
    profile: {
      _id: null,
      organisationName: null,
      address: {
        postalCode: null,
        street: null,
        city: null,
        country: null
      },
      website: null,

      logo: null,
      coverImg: null,

      emailAdress: null,
      contactPerson: null,
      missionOfOrganisation: null,
      organisationType: null,
      noOfMembers: null,
      telephoneNo: {
        code: null,
        country: null,
        no: null
      },
      noConfOrgAnnual: null,
      avgNoOfDelePerConf: null,
      user: null,
      topics: [],
      socialMedia: []
    },
    cityMatchId: "5e6b0e9552e5551b6c6d1419"
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_PROFILE(state, payload) {
      if (payload) {
        state.profile = {
          ...state.profile,
          ...payload
        };
        state.isProfile = true;
      }
    },
    SET_ORGANISATION_PROFILE_EL_INDEX(state, payload) {
      state.organisationProfileEl = payload;
    },
    SET_CITY_MATCH_ID(state, payload) {
      state.cityMatchId = payload;
    },
    CLEAR_DATA(state) {
      state.organisationProfileEl = 1;
      state.user = {
        userType: null,
        _id: null,
        name: null,
        email: null,
        organisation: null,
        created_at: null
      };
      state.isProfile = false;
      state.profile = {
        _id: null,
        organisationName: null,
        address: {
          postalCode: null,
          street: null,
          city: null,
          country: null
        },
        website: null,

        logo: null,
        coverImg: null,

        emailAdress: null,
        contactPerson: null,
        missionOfOrganisation: null,
        organisationType: null,
        noOfMembers: null,
        telephoneNo: {
          code: null,
          country: null,
          no: null
        },
        noConfOrgAnnual: null,
        avgNoOfDelePerConf: null,
        user: null,
        topics: null,
        socialMedia: []
      };
    }
  },
  actions: {
    setUser({ commit }, payload) {
      commit("SET_USER", payload.user);
      commit("SET_PROFILE", payload.profile);
    },
    setProfile({ commit }, payload) {
      commit("SET_PROFILE", payload);
    },
    setOrganisationProfileElIndex({ commit }, payload) {
      commit("SET_ORGANISATION_PROFILE_EL_INDEX", payload);
    },
    clearState({ commit }) {
      commit("CLEAR_DATA");
    },
    setCityMatchId({ commit }, payload) {
      commit("SET_CITY_MATCH_ID", payload);
    }
  },
  getters: {
    getUser: state => state.user,
    checkIsProfile: state => state.isProfile,
    getProfile: state => state.profile,
    getOrganisationProfileEl: state => state.organisationProfileEl,
    getIsProfile: state => state.isProfile,
    getCityMatchId: state => state.cityMatchId
  }
};
