export default {
  namespaced: true,
  state: {
    isSnackbar: false,
    snackBarText: "",
    snackBarColor: "secondary" // 'success', 'info', 'error'
  },
  mutations: {
    SET_SNACKBAR(state, payload) {
      state.isSnackbar = payload;
    },
    SET_SNACKBAR_MESSAGE(state, payload) {
      state.snackBarText = payload.snackBarText;
      state.snackBarColor = payload.snackBarColor;
      this.commit("notify/SET_SNACKBAR", true);
    }
  },
  actions: {
    setSnackbar({ commit }, payload) {
      commit("SET_SNACKBAR", payload);
    },
    setSnackbarMessage({ commit }, payload) {
      commit("SET_SNACKBAR_MESSAGE", payload);
    }
  },
  getters: {
    getSnackbar: state => state.isSnackbar,
    getSnackBarText: state => state.snackBarText,
    getSnackBarColor: state => state.snackBarColor
  }
};
