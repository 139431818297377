export default {
  namespaced: true,
  state: {
    singleRFP: {
      _id: null,
      isExibitSpace: null,
      subject: [],
      type: null,
      title: null,
      noOfDelegates: null,
      startDate: null,
      endDate: null,
      duration: null,
      noOfHotelRooms: null,
      noOfBreakoutRooms: null,
      exibitSpace: null,
      user: {
        _id: null,
        userType: null,
        name: null,
        email: null,
        organisation: null
      },
      cityMatch: {
        cityExcludeGoogleId: []
      },
      cityShortList: []
    },
    venueShortList: []
  },
  mutations: {
    SET_SINGLE_RFP(state, payload) {
      let singleRFP = JSON.parse(JSON.stringify(state.singleRFP));
      singleRFP = {
        ...payload
      };
      state.singleRFP = singleRFP;
    },
    CLEAR_STATE(state) {
      state.singleRFP = {
        _id: null,
        isExibitSpace: null,
        subject: [],
        type: null,
        title: null,
        noOfDelegates: null,
        startDate: null,
        endDate: null,
        duration: null,
        noOfHotelRooms: null,
        noOfBreakoutRooms: null,
        exibitSpace: null,
        user: {
          _id: null,
          userType: null,
          name: null,
          email: null,
          organisation: null
        },
        cityMatch: {
          cityExcludeGoogleId: []
        },
        cityShortList: []
      };
    }
  },
  actions: {
    setSingleRFP({ commit }, payload) {
      commit("SET_SINGLE_RFP", payload);
    },
    clearState({ commit }) {
      commit("CLEAR_STATE");
    }
  },
  getters: {
    getSingleRFP: state => state.singleRFP
  }
};
