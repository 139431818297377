import Vue from "vue";
import Router from "vue-router";
import Config from "./services/config";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/dashboard/Index"),
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/dashboard/Dashboard"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        // Views
        {
          name: "RFPs",
          path: "",
          component: () => import("@/dashboard/views/RFP/RFP"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "RFPs",
          path: "/rfp",
          component: () => import("@/dashboard/views/RFP/RFP"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "RFP - View",
          path: "/rfp-view/:id",
          component: () => import("@/dashboard/views/RFP/RFPView"),
          meta: {
            isDrawer: true,
            isDisable: false,
            isRequiresAuth: true
          }
        },
        {
          name: "CompletedRFPView",
          path: "/completed-rfp-view/:id",
          component: () => import("@/dashboard/views/RFP/RFPView"),
          meta: {
            isDrawer: true,
            isDisable: true,
            isRequiresAuth: true
          }
        },
        {
          name: "RFP - View",
          path: "/rfp-view2/:id",
          component: () => import("@/dashboard/views/RFP/RFPViewMine"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Completed RFP",
          path: "/completed-rfp",
          component: () =>
            import("@/dashboard/views/RFP/CompletedRFP/CompletedRFP"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Register",
          path: "/register",
          component: () => import("@/dashboard/views/Register"),
          meta: {
            isDrawer: false,
            isRequiresAuth: false
          }
        },
        {
          name: "Login",
          path: "/login",
          component: () => import("@/dashboard/views/LogIn"),
          meta: {
            isDrawer: false,
            isRequiresAuth: false
          }
        },
        {
          name: "CO Subscribers",
          path: "organiser-subscribers",
          component: () => import("./dashboard/views/Organiser/Organiser"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "CO Subscribers - View",
          path: "/organiser-view/:id",
          component: () => import("./dashboard/views/Organiser/OrganiserView"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "City Subscribers",
          path: "city-subscribers",
          component: () => import("./dashboard/views/City-Subscriber/CitySub"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "City Subscribers - View",
          path: "/city-subscribers-view/:id",
          component: () =>
            import("./dashboard/views/City-Subscriber/CitySubView"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Cities",
          path: "cities",
          component: () => import("./dashboard/views/City/City"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Country Subscribers",
          path: "Country-subscribers",
          component: () => import("./dashboard/views/Country-Subscriber/CountrySub"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Country Subscribers - View",
          path: "/country-subscribers-view/:id",
          component: () =>
            import("./dashboard/views/Country-Subscriber/CountrySubView"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Countries",
          path: "countries",
          component: () => import("./dashboard/views/Country/Country"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Venue Subscribers",
          path: "venue-subscribers",
          component: () => import("./dashboard/views/Venue/Venue"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Venue Subscribers - View",
          path: "/venue-subscribers-view/:id",
          component: () => import("./dashboard/views/Venue/VenueView"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },

        {
          name: "Transactions and Payments",
          path: "/transactions",
          component: () => import("./dashboard/views/Transaction/Transactions"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Bookings",
          path: "/bookings",
          component: () => import("./dashboard/views/Bookings/bookings"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Add-on Payments",
          path: "/add-on-payment",
          component: () => import("./dashboard/views/Direct-Contact/Direct-Contact"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Call for Tenders",
          path: "/call-for-tenders",
          component: () => import("./dashboard/views/tender/Tenders"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Call for Tenders",
          path: "/call-for-tenders/:id",
          component: () => import("./dashboard/views/tender/TenderDetails"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "City Payments",
          path: "/city-payments",
          component: () => import("./dashboard/views/Transaction/DirectPayments"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },

        {
          name: "Administrators",
          path: "/admins",
          component: () => import("./dashboard/views/admin/Admins"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        // {
        //   name: "Stripe Subscription Plan",
        //   path: "subscribePlan",
        //   component: () => import("./dashboard/views/Subscriber Strip plan/StripeSubscriptionPlan"),
        //   meta: {
        //     isDrawer: true
        //   }
        // },

        {
          name: "Upload Manuals",
          path: "/organiser-hybrid-manual",
          component: () => import("./dashboard/views/Hybrid/Hybrid"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Reviews",
          path: "/reviews",
          component: () => import("./dashboard/views/Reviews/Reviews"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Partner Promotions",
          path: "/Partner-Promotions",
          component: () => import("./dashboard/views/Partner-Promotions/Partner-Promotions"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Partner Promotion View",
          path: "/Partner-Promotion-View/:id",
          component: () => import("./dashboard/views/Partner-Promotions/Partner-Promotion-View"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Contacts",
          path: "/contacts",
          component: () => import("./dashboard/views/ContactTab/Contacts"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        // Pages
        {
          name: "User Profile",
          path: "/pages/user",
          component: () => import("@/dashboard/pages/UserProfile"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },

        {
          name: "Settings",
          path: "/settings",
          component: () => import("@/dashboard/views/Settings/Settings"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Log",
          path: "/cron",
          component: () => import("./dashboard/views/Cron/Cron"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },

        // Tables
        {
          name: "Regular Tables",
          path: "tables/regular-tables",
          component: () => import("@/dashboard/tables/RegularTables"),
          meta: {
            isDrawer: true,
            isRequiresAuth: true
          }
        },
        {
          name: "Forgot Password ",
          path: "forgot-pwd",
          component: () => import("./components/util/ForgotPwd"),
          meta: {
            isDrawer: false,
            isRequiresAuth: false
          }
        },
        {
          name: "Reset Password ",
          path: "reset-pwd",
          component: () => import("./components/util/ResetPwd"),
          meta: {
            isDrawer: false,
            isRequiresAuth: false
          }
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.isRequiresAuth)) {
    if (!Config.checkUser()) {
      next("/login");
    }
  } else if (Config.checkUser()) {
    // next("/");
  }

  next();
});

export default router;
