import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
Vue.component("font-awesome-icon", FontAwesomeIcon); // Register component globally
library.add(fab, fas); // Include needed icons

Vue.use(Vuetify);

const theme = {
  primary: "#35A7FF",
  secondary: "#313638",
  accent: "#589FD7",
  error: "#F06543",
  info: "#313638",
  success: "#4CAF50",
  warning: "#FFC107",
  anchor: "#35A7FF",
  conferliBlue: "#35A7FF",
  platinum: "E8E9EB",
  bedrock: "313638",
  attention: "F06543"
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: theme,
      light: theme
    }
  }
});
