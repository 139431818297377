export default {
  namespaced: true,
  state: {
    citySubscriberProfileEl: 1,
    user: {
      type: null,
      userType: null,
      _id: null,
      name: null,
      email: null,
      cityName: null,
      created_at: null,
      coordinates: [],
      googlePlaceId: null,
      googleMapURL: null,
      formattedAddress: null
    },
    isProfile: false,
    profile: {
      _id: null,
      cityName: null,
      address: {
        postalCode: null,
        street: null,
        city: null,
        country: null
      },
      website: null,
      proof: null,
      type: null,

      logo: null,
      coverImg: null,

      emailAddress: null,
      telephoneNo: {
        code: null,
        country: null,
        no: null
      },
      user: null,
      topics: [],
      socialMedia: [],
      contactPerson: null
    }
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_PROFILE(state, payload) {
      if (payload) {
        state.profile = {
          ...state.profile,
          ...payload
        };
        state.isProfile = true;
      }
    },
    SET_CITY_SUBSCRIBER_PROFILE_EL_INDEX(state, payload) {
      state.citySubscriberProfileEl = payload;
    },
    CLEAR_DATA(state) {
      state.citySubscriberProfileEl = 1;
      state.user = {
        type: null,
        userType: null,
        _id: null,
        name: null,
        email: null,
        cityName: null,
        created_at: null,
        coordinates: [],
        googlePlaceId: null,
        googleMapURL: null,
        formattedAddress: null
      };
      state.isProfile = false;
      state.profile = {
        _id: null,
        cityName: null,
        address: {
          postalCode: null,
          street: null,
          city: null,
          country: null
        },
        website: null,
        proof: null,
        type: null,

        logo: null,
        coverImg: null,

        emailAddress: null,
        telephoneNo: {
          code: null,
          country: null,
          no: null
        },
        user: null,
        topics: [],
        socialMedia: [],
        contactPerson: null
      };
    }
  },
  actions: {
    setUser({ commit }, payload) {
      commit("SET_USER", payload.user);
      commit("SET_PROFILE", payload.profile);
    },
    setProfile({ commit }, payload) {
      commit("SET_PROFILE", payload);
    },
    setCitySubscriberProfileElIndex({ commit }, payload) {
      commit("SET_CITY_SUBSCRIBER_PROFILE_EL_INDEX", payload);
    },
    clearState({ commit }) {
      commit("CLEAR_DATA");
    }
  },
  getters: {
    getUser: state => state.user,
    getProfile: state => state.profile,
    getCitySubscriberProfileEl: state => state.citySubscriberProfileEl,
    getIsProfile: state => state.isProfile
  }
};
