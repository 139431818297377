export default {
  namespaced: true,
  state: {
    user: {
      userType: null,
      _id: null,
      venueName: null,
      website: null,
      name: null,
      email: null,
      created_at: null
    },
    venueProfileEl: 1,
    profile: {
      _id: null,
      user: null,
      venueName: null,
      address: {
        street: null,
        postalCode: null,
        city: null,
        country: null
      },
      website: null,

      logo: null,
      coverImg: null,

      emailAddress: null,
      telephoneNo: {
        code: null,
        country: null,
        no: null
      },
      socialMedia: [],
      contactPerson: null,
      gmPerson: {
        name: null,
        emailAddress: null
      },
      RFPPerson: {
        name: null,
        emailAddress: null
      },
      finance: {
        name: null,
        emailAddress: null
      },
      maxNoOfDelegateStyle: [],
      noOfHotelRooms: []
    },
    isProfile: false
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_PROFILE(state, payload) {
      if (payload) {
        state.profile = {
          ...state.profile,
          ...payload
        };
        state.isProfile = true;
      }
    },
    SET_VENUE_PROFILE_EL_INDEX(state, payload) {
      state.venueProfileEl = payload;
    },
    CLEAR_DATA(state) {
      state.user = {
        userType: null,
        _id: null,
        venueName: null,
        website: null,
        name: null,
        email: null,
        created_at: null
      };
      state.venueProfileEl = 1;
      state.profile = {
        _id: null,
        user: null,
        venueName: null,
        address: {
          street: null,
          postalCode: null,
          city: null,
          country: null
        },
        website: null,

        logo: null,
        coverImg: null,

        emailAddress: null,
        telephoneNo: {
          code: null,
          country: null,
          no: null
        },
        socialMedia: [],
        contactPerson: null,
        gmPerson: {
          name: null,
          emailAddress: null
        },
        RFPPerson: {
          name: null,
          emailAddress: null
        },
        finance: {
          name: null,
          emailAddress: null
        },
        maxNoOfDelegateStyle: [],
        noOfHotelRooms: []
      };
      state.isProfile = false;
    }
  },
  actions: {
    setVenueProfileElIndex({ commit }, payload) {
      commit("SET_VENUE_PROFILE_EL_INDEX", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload.user);
      commit("SET_PROFILE", payload.profile);
    },
    setProfile({ commit }, payload) {
      commit("SET_PROFILE", payload);
    },
    clearState({ commit }) {
      commit("CLEAR_DATA");
    }
  },
  getters: {
    getUser: state => state.user,
    getVenueProfileEl: state => state.venueProfileEl,
    getProfile: state => state.profile,
    getIsProfile: state => state.isProfile
  }
};
