<template>
  <router-view />
</template>

<script>
// import Config from "./services/config";
export default {
  name: "App",
  mounted() {
    // if (!Config.checkUser()) {
    //   localStorage.clear();
    //   location.replace("/login");
    // }
  }
};
</script>
<style>
html,
body {
  height: 100%;
}
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}
.feature-icon {
  font-size: 12px !important;
  width: 24px !important;
  height: 24px !important;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}
/* global table classes */
/* sortable arrow position fix */
th.sortable {
  position: relative;
}
th.sortable i.v-icon {
  position: absolute;
  top: 15px;
}
/* overflow fix for dialog */
.v-dialog {
  overflow-x: hidden;
}
/* button color according to Conferli Brand Guideline */
.v-btn.v-btn--tile:hover::before {
  background-color: var(--v-primary-base);
  opacity: 1 !important;
}
.v-btn.v-btn--tile::before {
  background-color: var(--v-secondary-base);
}
.v-btn.v-btn--tile:active::before {
  background-color: var(--v-error-base);
  opacity: 1 !important;
}
.v-btn.v-btn--tile:focus::before {
  background-color: var(--v-primary-base);
  opacity: 1 !important;
}
/* not button icons */
.v-btn.v-btn--icon:hover::before {
  background-color: currentColor;
  opacity: 0.04 !important;
}
.v-btn.v-btn--icon::before {
  background-color: currentColor;
}
.v-btn.v-btn--icon:active::before {
  background-color: currentColor;
}
.v-btn.v-btn--icon:focus::before {
  background-color: currentColor;
  opacity: 0.12 !important;
}
/* v-dialog issues */
.v-dialog__content .v-dialog.v-dialog--active {
  overflow: hidden !important;
}
.v-dialog .v-card .v-btn.v-btn--icon {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
</style>
